import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import HeroPost from "./style"
import { useStaticQuery, graphql } from "gatsby"
import Images from "~data/imageImports"
import { GatsbyImage as Img } from "gatsby-plugin-image"
import Slider from "react-animated-slider"
import "react-animated-slider/build/horizontal.css"

var settings = {
  touchDisabled: true,
  autoplay: 3000,
  infinite: true,
  minSwipeOffset: 20,
  duration: 2000,
}

export default function HeroSection({
  children,
  frontmatter,
  height,
  adsPage,
  servicePage,
  homePage,
  postPage,
  color,
}) {
  // const sliderMap = homePage?.nodes.map((slide, index) => {
  //   let theImage = slide.frontmatter.heroImage?.childImageSharp.gatsbyImageData.images.fallback.src
  //   let desc = { __html: slide.frontmatter.description?.slice(0, 130).concat("...") }
  //   return (
  //     <div key={index} className="slider-content" style={{ background: `url('${theImage}') no-repeat center center` }}>
  //       <div className="inner">
  //         <h1 className="slider-title">{slide.frontmatter.title}</h1>
  //         <p className="slider-description">{<span dangerouslySetInnerHTML={desc} />}</p>
  //         {slide.frontmatter.type === "ads" && (
  //           <a href={`/ads${slide.fields.slug}`} className="btn btn-white rounded-pill py-3 me-auto">
  //             {"Learn more"}
  //           </a>
  //         )}
  //         {slide.frontmatter.type === "post" && (
  //           <a href={`/blog${slide.fields.slug}`} className="btn btn-white rounded-pill py-3 me-auto">
  //             {"Learn more"}
  //           </a>
  //         )}
  //       </div>
  //     </div>
  //   )
  // })

  return (
    <>
      {postPage && (
        <>
          <HeroPost
            height={height}
            backgroundImage={frontmatter.heroImage?.childImageSharp.gatsbyImageData.images.fallback.src}
            className={"post-hero"}></HeroPost>
          <br />
          <br />
        </>
      )}
    </>
  )
}
