import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import RelatedServicesStyle from "./style"
import RelatedServicesCard from "./Components/Card"

const RelatedSection = ({ relatedServices }) => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        sort: { fields: frontmatter___weight, order: ASC }
        filter: { fileAbsolutePath: { regex: "/(services)/" }, frontmatter: { status: { eq: true } } }
      ) {
        edges {
          node {
            slug
            frontmatter {
              title
              heroTitle
              category
              weight
              aboutImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, height: 300, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
              }
              heroImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, height: 300, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
              }
            }
          }
        }
      }
    }
  `)

  const matchFilter = data.allMdx.edges.filter(({ node }) => relatedServices?.includes(node.slug))
  const result = useMemo(
    () =>
      matchFilter.map((obj) => {
        const { frontmatter, slug } = obj.node
        const { title, heroTitle, category, aboutImage } = frontmatter
        return { title, heroTitle, category, aboutImage, slug }
      }),
    [matchFilter]
  )
  const match = result.slice(0, 2)

  return (
    <RelatedServicesStyle>
      <Container>
        {match.length > 0 && (
          <Row className="align-items-center justify-content-center">
            <Col col="12" className="col-12 text-center">
              <RelatedServicesStyle.Box mb="30px" mbLG="50px">
                <RelatedServicesStyle.Title as="h3">Related Services</RelatedServicesStyle.Title>
              </RelatedServicesStyle.Box>
            </Col>
          </Row>
        )}
        <Row className="justify-content-center">
          {match.map((item, index) => {
            return (
              <Col className="col-md-6 col-xs-12 col-12" key={"related-" + index}>
                <RelatedServicesCard
                  to={`/services/${item.slug}`}
                  image={item.aboutImage || item.heroImage}
                  title={item.title || item.heroTitle}
                  text={item.category}
                />
              </Col>
            )
          })}
        </Row>
      </Container>
    </RelatedServicesStyle>
  )
}
export default RelatedSection
