import React, { useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image"
import { SEO } from "~components"
import { useSiteMetadata } from "~hooks/use-site-metadata"
import { Col, Container, Row, Button, Offcanvas } from "react-bootstrap"
import { PageWrapper, Link } from "~components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import RelatedServices from "~components/RelatedServices"
import CtaSection from "~components/Cta"
import FooterBlock from "~components/FooterBlock"
import FooterSimple from "~components/FooterSimple"
import HeroPostSection from "~components/HeroPost"
import ContentSectionRight from "~components/ContentRight"
import moment from "moment"
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from "react-share"
import ContentStyle from "./style"

// mobile-sticky-enable
const header = {
  headerClasses:
    "site-header site-header--menu-end dark-header site-header--sticky position-relative site-header--menu-sep mobile-sticky-enable",
  containerFluid: false,
  darkLogo: false,
  buttonBlock: (
    <Link
      title={"Gift Cards"}
      className="btn btn-outline-secondary rounded-pill ms-auto ms-lg-0 d-none d-xs-inline-flex btn-sm"
      link="https://www.vagaro.com/Users/BusinessWidget.aspx?enc=MMLjhIwJMcwFQhXLL7ifVGOwOvJkWSUu0HWpFHcIQKdziAzzXN6MkSKz4rU2l1PnM3MEg0m2hqPQWlqNqXcA14R4IqMe4FpU2ds9IcrYPL9zxOg7Q54n04kDy63+MfHDu99ov59H8Ij505cqCz81BQN8bMWMaAbHb1TtZMNcNNSD9cEnGtY8UzntIRtke/hIaN74IzpmrSgy1UUsr9IthvlgfvKwPpOVYw0Gn2GCeJ9yuXa9X+vS+kN1VJfSGxizZv7xHtZd+Ct3dpE9VpNzge6cI16+VNesvPxgK3VO+eoaNwXwze/CLbVYlz5t4BHEKmHwTiVfLUvozETgr+SQzQ==">
      <span>
        <i className="fa fa-gift"></i>
        {"  "}Gift Cards
      </span>
    </Link>
  ),
}

export default function ServicePage(queryData) {
  let pageContext = queryData.pageContext
  const [show, setShow] = useState(false)
  const siteMetadata = useSiteMetadata()
  const { body, fields, frontmatter } = pageContext.data
  const content = pageContext.data
  const { title: siteTitle, siteUrl, siteLanguage, siteLocale } = useSiteMetadata()
  const conditions = ["http", "tel:", "mail:"]

  const shortcodes = { Link, ContentSectionRight } // Provide common components here

  React.useEffect(() => {}, [])
  return (
    <PageWrapper headerConfig={header}>
      <SEO
        titleTemplate={`${siteTitle}`}
        titleSeparator={`-`}
        title={`${content.frontmatter.title}`}
        description={`${content.frontmatter.seoDescription || content.frontmatter.description || ""}`}
        type={`${siteMetadata.type}`}
        location={`${siteUrl}${fields.slug}`}
        image={`${
          frontmatter?.heroImage?.childImageSharp.gatsbyImageData.images.fallback.src ||
          frontmatter?.aboutImage?.childImageSharp.gatsbyImageData.images.fallback.src
        }`}
        pathname={siteUrl}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        keywords={content.frontmatter.keywords}
        indexed="true"
      />

      <span className="py-3" />
      <Container>
        <Row className="justify-content-center">
          {/* <span className="my-6" /> */}
          <Col className="col-lg-12 col-12 post-container">
            <div className="post-content">
              <Container>
                <Row className="">
                  <Col className="col-xs-12">
                    <ContentStyle.Content>
                      {console.log(`content.frontmatter.date: `, content.frontmatter)}
                      {console.log(moment(content.frontmatter.date).format("LL"))}
                      <p>{moment(content.frontmatter.date).format("LL")}</p>
                      <ContentStyle.Subtitle as="h5" fontColor="#feb856" mb="12px">
                        {content.frontmatter.heroTag ? content.frontmatter.heroTag : content.frontmatter.category}
                      </ContentStyle.Subtitle>
                      <ContentStyle.Title as="h1" fontColor="#000">
                        {content.frontmatter.heroTitle || content.frontmatter.title}
                      </ContentStyle.Title>
                    </ContentStyle.Content>
                  </Col>
                </Row>
              </Container>
              <br />
              <HeroPostSection frontmatter={content.frontmatter} slider={false} height={"400px"} postPage />

              <MDXProvider
                components={{
                  a: (props) => (
                    <a href={props.href} className="post-link" target="_blank">
                      {props.children}
                    </a>
                  ),
                }}>
                <MDXRenderer localImages={content.frontmatter.embeddedImagesLocal}>{body}</MDXRenderer>
              </MDXProvider>
            </div>
          </Col>
          <Col className="col-lg-12 col-12 post-container">
            <div className="post-content">
              <div>
                <h6>Share this:</h6>
              </div>
              {console.log(`content.frontmatter: `, content)}
              <div>
                <FacebookShareButton
                  url={`https://essence-medspa.com/blog${content.fields.slug}`}
                  quote={content.frontmatter.title}
                  hashtag="essence">
                  <FacebookIcon size={32} round />
                </FacebookShareButton>{" "}
                <TwitterShareButton
                  url={`https://essence-medspa.com/blog${content.fields.slug}`}
                  quote={content.frontmatter.title}
                  hashtags={["essence"]}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {content.frontmatter.category && <RelatedServices relatedServices={content.frontmatter.relatedServices} />}
      {!content.frontmatter.category && <span className="py-5" />}
      <CtaSection />
      <FooterBlock />
      <FooterSimple />
    </PageWrapper>
  )
}
